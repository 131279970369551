import React from "react";
import { Spin } from "antd";
import "./index.scss";

const GlobalSpinner = () => {
  return (
    <div className="global-spinner">
      <Spin />
    </div>
  );
};

export default GlobalSpinner;
